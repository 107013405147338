import React, { useState, useEffect } from 'react';
import QrReader from 'react-qr-reader';
import useSound from 'use-sound';

import './App.css';
import beep from './beep.wav';

function App() {
    const [result, setResult] = useState([]);

    const [playBeep] = useSound(beep, { volume: 1.5 });

    useEffect(() => console.log(result), [result]);

    const scanHandler = data => {
        if (data && result[result.length - 1] !== data) {
            setResult(result.concat(data));
            playBeep();
        }
    };

    const errorHandler = err => console.error(err);

    return (
        <div className="App">
            <QrReader
                delay={100}
                onScan={scanHandler}
                onError={errorHandler}
                style={{ width: '100%' }}
            />
            <ul>{result && result.map((data, i) => <li key={i}>{data}</li>)}</ul>
            <p>2020 &copy; KennyP</p>
        </div>
    );
}

export default App;
